<template>
  <div id="notification_tab">
    <div v-if="notis">
      <div class="card border-0">
        <div
          class="card-body card-body-scrollable card-body-scrollable-shadow p-0"
          style="background: #F4F6FA;"
        >
          <div v-if="!loading_content" class="notification-tab">
            <div class="noti-close" @click="closeNoti()"></div>
            <div
              v-for="noti in notis.data"
              :key="noti.id"
              :style="notis.data&&noti.id === notis.data[0].id ? 'margin: 24px 16px 11px 14px;' : 'margin: 14px 16px 11px 14px;'"
              style="margin: 14px 16px 11px 14px; border-radius: 6px;"
            >
              <div class="card-noti" @click="readNotification(noti)">
                <div class="d-flex pb-1 border-bottom justify-content-between">
                  <div class="d-flex align-items-center">
                    <div :class="getTypeNoti(noti) ? getTypeNoti(noti).noticlass : ''"></div>
                    <div class="ml-2 d-flex align-items-center robo-16-500 txt-pri">
                      <span v-if="noti.data">{{noti.data.title}}</span>
                    </div>
                    <div v-if="!noti.read_at" class="ml-2 d-flex align-items-center badge bg-red"></div>
                  </div>
                  <div
                    class="ml-2 d-flex align-items-center justify-content-end robo-14-400 text-black-50"
                  >{{formatDateAgo(noti.created_at)}}</div>
                </div>
                <div style="padding: 10px 0 10px 0;">
                  <div v-if="!noti || !noti.data || (noti.data && !noti.data.body)">{{noti.message}}</div>
                  {{noti.data.body}}
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading_content" class="divide-y-4 notification-tab">
            <ul class="list-group list-group-flush">
              <li v-for="i in 10" class="list-group-item" :key="i + 'notifl'">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="skeleton-avatar"></div>
                  </div>
                  <div class="col-7">
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line"></div>
                  </div>
                  <div class="col-2 ms-auto text-end">
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixinUser } from '../utils/mixinUser'
import { mixinNotification } from '../utils/mixinNotification'
import appUtils from '../utils/appUtils'
export default {
  name: 'NotificationListing',
  mixins: [mixinUser, mixinNotification],
  props: ['notis', 'loading'],
  data () {
    return {
      appUtils,
      user: null,
      currentRouteName: '',
      loading_content: false,
      notifications: [],
      person: [],
      doctor: null,
      clinic: null
    }
  },
  watch: {
    $route (to, from) {
      let self = this
      if (self.currentRouteName === to.name) {
        self.$router.go()
      } else {
        self.currentRouteName = to.name
      }
    }
  },
  computed: {
    roles () {
      if (this.appUtils.isAdmin()) {
        return {
          class_name: 'AdminRequest',
          url_name: 'admin'
        }
      } else if (this.appUtils.isClinic()) {
        return {
          class_name: 'ClinicRequest',
          url_name: 'clinic'
        }
      } else if (this.appUtils.isDoctor()) {
        return {
          class_name: 'DoctorRequest',
          url_name: 'doctor'
        }
      } else {
        return {
          class_name: '',
          url_name: ''
        }
      }
    },
    isAdmin () {
      return this.appUtils.isAdmin()
    },
    isDoctor () {
      return this.appUtils.isDoctor()
    },
    hasNextPage () {
      let self = this
      if (self.notis) {
        return self.notis.count < self.notis.total
      }
    }
  },
  async mounted () {
    let self = this
    self.doctor = self.appUtils.getLocalUser()
    self.clinic = this.$globalClinic
    // self.loading_content = true
    // setTimeout(() => {
    //   self.loading_content = false
    // }, 1000)
    await self.getCurrentUser()
    self.currentRouteName = self.$router.currentRoute.name
    setTimeout(() => {
      self.sendAmplitude('Open Notification', {
        'doctor id':
          self.doctor && self.doctor.doctor && self.doctor.doctor.id
            ? self.doctor.doctor.id
            : '',
        'doctor name': self.doctor && self.doctor.name ? self.doctor.name : '',
        'workspace id': self.clinic && self.clinic.id ? self.clinic.id : '',
        'workspace name':
          self.clinic && self.clinic.name ? self.clinic.name : '',
        timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY')
      })
    }, 100)
  },
  methods: {
    sortNoti (notis) {
      return notis.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at)
      })
    },
    getTitle (noti_id) {
      let self = this
      let result = self.notifications.find(noti => noti.id === noti_id)
      return result ? result.title : ''
    },
    getTypeNoti (noti) {
      let array = [
        { noticlass: 'noti-default noti-circle', name: 'Doctor Circle' },
        { noticlass: 'noti-default noti-appointment', name: 'Lịch làm việc' },
        { noticlass: 'noti-default noti-request', name: 'Yêu cầu khám' },
        { noticlass: 'noti-default noti-alter', name: 'Thông báo' },
        { noticlass: 'noti-default noti-admin', name: 'Thông báo từ HODO' },
        { noticlass: 'noti-default noti-request', name: 'Chỉ dẫn' },
        { noticlass: 'noti-default noti-invoice', name: 'Hoàn tất thanh toán' },
        {
          noticlass: 'noti-default noti-appointment',
          name: 'Xác nhận lịch hẹn'
        },
        { noticlass: 'noti-default noti-request', name: 'Phân công hỗ trợ' },
        { noticlass: 'noti-default noti-cancer', name: '' },
        { noticlass: 'noti-default noti-topic', name: '' }
      ]
      if (noti) {
        if (
          noti.type.indexOf('NewAppointmentPatientNotify') > -1 ||
          noti.type.indexOf('NewAppointmentDoctorNotify') > -1 ||
          noti.type.indexOf('NextAppointmentNotify') > -1 ||
          noti.type.indexOf('AppointmentPatientCancelNotify') > -1
        ) {
          return array[1]
        } else if (
          noti.type.indexOf('PatientSendRequestToDoctorNotify') > -1 ||
          noti.type.indexOf('RequestPatientRejectNotify') > -1 ||
          noti.type.indexOf('RequestTransferedNotify') > -1 ||
          noti.type.indexOf('RequestDoctorRejectNotify') > -1
        ) {
          return array[2]
        } else if (
          noti.type.indexOf('NewFriendRequestSendNotify') > -1 ||
          noti.type.indexOf('RejectFriendRequestSendNotify') > -1
        ) {
          return array[0]
        } else if (noti.type.indexOf('SendIndicationCheckinNotify') > -1) {
          return array[3]
        } else if (noti.type.indexOf('CampaignNotify') > -1) {
          return array[4]
        } else if (
          noti.type.indexOf('TicketPatientFeedbackNotify') > -1 ||
          noti.type.indexOf('TicketPatientRejectNotify') > -1
        ) {
          return array[5]
        } else if (noti.type.indexOf('InvoicePatientPaidNotify') > -1) {
          return array[6]
        } else if (noti.type.indexOf('AppointmentPatientAcceptNotify') > -1) {
          return array[7]
        } else if (noti.type.indexOf('DoctorAddMemberToRequestNotify') > -1) {
          return array[8]
        } else if (
          noti.type.indexOf('CarePlanInviteDoctorNotify') > -1 ||
          noti.type.indexOf('CarePlanHasNewSymptomNotify') > -1 ||
          noti.type.indexOf('CarePlanHasNewDocumentNotify') > -1 ||
          noti.type.indexOf('PatientOverSurvivalNotify') > -1 ||
          noti.type.indexOf('CarePlanSignUpdatedNotify') > -1
        ) {
          return array[9]
        } else if (noti.type.indexOf('PersonPostTopicCommentNotify') > -1) {
          return array[10]
        }
      }
    },
    isRouteNames (routeNames = []) {
      let self = this
      return routeNames.includes(self.currentRouteName)
    },
    async readNotification (noti) {
      let self = this
      if (!noti.read_at) {
        await self.$rf
          .getRequest(self.roles.class_name)
          .getNotificationDetail(noti.id)
      }
      if (noti.data.class === 'App\\Circles\\IndicationResult') {
        const patientVisitId = parseInt(noti.data.patient_visit_id)
        console.log(patientVisitId)
        self.$router.push({
          name: 'PatientVisitDetail',
          params: { id: patientVisitId }
        })
      }
      self.$emit('onCloseNoti', false)
      self.goToNotification(noti)
    },
    getType (noti, type) {
      if (noti) {
        return noti.type.indexOf(type) > -1
      }
    },
    formatTimeForAppt (date_time) {
      if (window.moment(date_time).isSame(window.moment(), 'day')) {
        return window.moment(date_time).format('HH:mm') + ' hôm nay'
      } else {
        return this.formatTimeDate(date_time)
      }
    },
    formatDateAgo (date) {
      return window.moment(date, 'YYYY-MM-DD hh:mm:ss').fromNow()
    },
    formatTimeDate (date_time) {
      return window.moment(date_time).format('HH:mm DD-MM-YYYY')
    },
    closeNoti () {
      this.$emit('onCloseNoti', false)
    }
  }
}
</script>
<style scoped>
.noti-default {
  height: 28px;
  width: 28px;
  background-size: 28px;
}
.noti-circle {
  background-image: url("../../public/assets/images/icon/icon_circle_blue.svg");
}
.noti-appointment {
  background-image: url("../../public/assets/images/icon/appointment.svg");
}
.noti-request {
  background-image: url("../../public/assets/images/icon/request.svg");
}
.noti-alter {
  background-image: url("../../public/assets/images/icon/navbar/Notification/def.svg");
}
.noti-admin {
  background-image: url("../../public/assets/images/icon/noti_admin.svg");
}
.noti-invoice {
  background-image: url("../../public/assets/images/icon/invoice.svg");
}
.noti-cancer {
  background-image: url("../../public/assets/images/icon/icon-cancer.svg");
}
.noti-topic {
  background-image: url("../../public/assets/images/icon/icon-topic.svg");
}
.noti-close {
  height: 20px;
  width: 20px;
  background-size: 20px;
  background-image: url("../../public/assets/images/icon/icon_close.svg");
  margin: 42px 0px 0px 24px;
  cursor: pointer;
}
.card-noti {
  background: #ffffff;
  padding: 4px 7px;
  border-radius: 6px;
  cursor: pointer;
}
</style>
