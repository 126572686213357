<template>
  <div>
    <div class="bg-white rounded-2xl p-6 h-auto overflow-auto mt-4">
      <div class="d-flex justify-content-end mb-3">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer"
          @click="handleBackSubOrgList"
        >
          <path
            d="M6.5293 17.9998L18.5291 6"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.5291 17.9998L6.5293 6"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="flex items-center justify-between">
        <div class="txt-grey-900 text-xl font-bold">{{ title }}</div>
        <div class="flex items-center justify-between">
          <div class="flex items-center justify-center border rounded-md border-grey-300 p-2 mr-2">
            <IconSearch class="cursor-pointer" />
            <input
              @keypress.enter="getOrgMembers"
              class="border-0 txt-grey-500 w-64 ml-2 focus:outline-none"
              :placeholder="$t('lbl_enter_staff_name')"
              v-model="searchValue"
            />
          </div>
          <ButtonHoDo
            v-show="stat === 1 || stat === 2"
            :title="$t('lbl_add_staff_short')"
            class-name="bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
            @click="onShowModalAddEmployee(true)"
          />
        </div>
      </div>

      <div class="flex text-blue-700 text-sm font-semibold mb-6">
        <div
          class="cursor-pointer p-2.5 mr-3"
          :class="{ 'border-bottom-2': checkActive() }"
          @click="setActive()"
        >{{ $t('lbl_joined') }}</div>
        <div
          class="cursor-pointer p-2.5 mr-3"
          :class="{ 'border-bottom-2': checkInvited() }"
          @click="setInvited()"
        >{{ $t('lbl_invited') }}</div>
        <!-- <div
            class="cursor-pointer p-2.5 mr-3"
            :class="{'border-bottom-2': checkWaiting()}"
            @click="setWaiting()"
        >Yêu cầu tham gia</div>-->
      </div>

      <div
        class="grid gap-4 bg-grey-100 rounded-lg p-2 mb-2"
        :class="isShowAssignRole ? 'grid-cols-8' : 'grid-cols-7'"
      >
        <div class="col-span-2 txt-grey-700 text-sm font-semibold">{{ $t('lbl_full_name') }}</div>
        <div
          class="txt-grey-700 text-sm font-semibold"
          :class="isShowAssignRole ? 'col-span-2' : 'col-span-3'"
        >{{ $t('lbl_workspace_position') }}</div>
        <div
          class="col-span-1 txt-grey-700 text-sm font-semibold text-center"
        >{{ $t('lbl_job_title') }}</div>
        <div
          v-if="isShowAssignRole"
          class="col-span-2 txt-grey-700 text-sm font-semibold text-center"
        >{{ $t('lbl_permissions') }}</div>
        <div class="txt-grey-700 text-sm font-semibold text-center">{{ $t('lbl_action') }}</div>
      </div>

      <div
        class="grid gap-4 p-3 border-bottom border-grey-700 cursor-pointer custom"
        :class="isShowAssignRole ? 'grid-cols-8' : 'grid-cols-7'"
        v-for="(member, index) in dataOrgMember"
        :key="index"
        @click="goToProfile(member.doctor_id)"
      >
        <div class="col-span-2">
          <div class="flex items-center">
            <div>
              <img
                v-if="member.avatar"
                class="w-9 h-9 object-cover mr-2.5 rounded-full"
                :src="appUtils.getImageURL(member.avatar)"
              />
              <div
                v-else
                class="bg-pri text-white flex justify-center items-center w-9 h-9 mr-2.5 rounded-full"
              >
                <div class="fs-16">{{ member.name ? member.name.charAt(0) : "905" }}</div>
              </div>
            </div>
            <div>
              <div class="txt-grey-900 text-sm font-semibold leading-none mb-0.5">{{ member.name }}</div>
              <div class="txt-grey-700 text-xs font-medium leading-none mb-0.5">{{ member.email }}</div>
              <div class="txt-grey-700 text-xs font-medium leading-none">{{ member.phone }}</div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col justify-center"
          :class="isShowAssignRole ? 'col-span-2' : 'col-span-3'"
        >
          <div
            class="txt-grey-900 text-sm font-semibold leading-none mb-0.5"
          >{{ member.organization_name }}</div>
          <div
            v-for="clinic in member.clinics"
            :key="clinic.id"
            class="txt-grey-700 text-sm font-semibold leading-none mb-0.5"
          >{{ clinic.name }}</div>
          <div class="txt-grey-700 text-xs font-medium leading-none mb-1">{{ member.position }}</div>
        </div>
        <div
          class="col-span-1 flex flex-col justify-center items-center"
        >{{ mdtUtils.getRoleMember(member.type) }}</div>
        <div
          v-if="isShowAssignRole"
          class="edit-cell col-span-2 flex flex-col items-center justify-center"
          @click.stop
        >
          <div
            v-for="service in member.service_list"
            :key="service.id"
            class="txt-grey-700 text-sm font-semibold leading-none mb-0.5"
          >{{ service.name }}</div>
          <div
            v-for="service in member.services"
            :key="service.id"
            class="txt-grey-700 text-xs font-medium leading-none mb-1"
          >{{ service.role.name }}</div>
        </div>
        <div class="col-span-1 flex justify-center items-center" @click.stop>
          <div v-if="member.role !== 1" class="h-7 w-7" @click="deleteMember(member)">
            <Delete class="cursor-pointer" />
          </div>
          <div
            v-if="isShowAssignRole"
            class="h-4 w-4"
            @click="onShowModalAssignDuty(true, member.id, member.user_id)"
          >
            <Edit class="edit-icon cursor-pointer" />
          </div>
        </div>
      </div>

      <AppPagination :paginationProps="pagination" @onChangePagination="getOrgMembers" />
    </div>

    <ModalAddEmployee @reload="getOrgMembers" />
    <ModalAssignEmployee
      v-if="isShowAssignRole && org_member_id"
      :count_service="count_service"
      :org_member_id="org_member_id"
      :user_id="user_id"
      @modalClose="handleModalClose"
      @reload="getOrgMembers"
    />
  </div>
</template>
  
<script>
import appUtils from '../utils/appUtils'
import mdtUtils from '../utils/mdtUtils'
import { DEFAULT_SIZE_OF_PAGE } from '../utils/constants'
import { AppPagination } from '@/components/Common'

import {
  IconTQM,
  IconWorkspace,
  IconSettings,
  IconSearch,
  IconAdd,
  Delete,
  Edit
} from '@/components/Icon'
import { ButtonHoDo } from '@/components/Button'
import ModalAssignEmployee from '../pages/Organization/ModalAssignEmployee.vue'
import ModalAddEmployee from '../pages/Organization/ModalAddEmployee.vue'

export default {
  name: 'OrgListEmployee',
  props: ['orgId', 'title'],
  components: {
    ModalAssignEmployee,
    ModalAddEmployee,
    IconTQM,
    IconWorkspace,
    IconSettings,
    IconSearch,
    IconAdd,
    ButtonHoDo,
    Delete,
    Edit,
    AppPagination
  },
  data () {
    return {
      org: '',
      dataOrgMember: null,
      mdtUtils,
      appUtils,
      searchValue: null,
      stat: 1,
      DEFAULT_SIZE_OF_PAGE,
      totalItems: 0,
      page: 1,
      count_service: 0,
      org_member_id: 0,
      user_id: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },
  computed: {
    isShowAssignRole () {
      return (
        this.count_service &&
        this.stat === 1 &&
        this.checkRoleOwnerOrg(this.org || this.$user)
      )
    },
    doctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    },

    getDayOfWeek () {
      return window.moment().format('dddd')
    },

    getDay () {
      return window.moment().format('DD')
    },

    getMonth () {
      return window.moment().format('MM')
    },

    getYear () {
      return window.moment().format('YYYY')
    }
  },
  mounted () {
    this.getOrg(this.orgId)
    this.getOrgMembers()
    if (this.checkRoleOwnerOrg(this.org || this.$user)) {
      this.checkifOrgHasServices()
    }
  },
  methods: {
    goToProfile (id) {
      if (!id) return
      this.$router.push({ name: 'DoctorProfile', params: { id: id } })
    },
    async getOrg (id) {
      if (!id) return
      const res = await this.$rf.getRequest('DoctorRequest').getOrg(id)
      this.org = res.data
    },
    checkActive () {
      return this.stat === 1
    },
    setActive () {
      this.stat = 1
      this.getOrgMembers()
    },
    checkInvited () {
      return this.stat === 2
    },
    setInvited () {
      this.stat = 2
      this.getOrgMembers()
    },
    checkWaiting () {
      return this.stat === 3
    },
    setWaiting () {
      this.stat = 3
      this.getOrgMembers()
    },
    onShowModalAddEmployee (show) {
      if (!this.checkRoleOwnerOrg(this.org || this.$user)) {
        alert('Bạn không có quyền thêm nhân sự trong Org này')
        return
      }
      window.$('#modal--add-employee').modal(show ? 'show' : 'hide')
    },
    handleModalClose () {
      this.org_member_id = 0
      this.user_id = 0
    },
    async checkifOrgHasServices () {
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .checkIfOrgHasServices(this.orgId)
      this.count_service = res.data.count
    },
    onShowModalAssignDuty (show, org_member_id, user_id) {
      this.org_member_id = org_member_id
      this.user_id = user_id
      this.$nextTick(() => {
        window.$('#modal--assign-employee').modal(show ? 'show' : 'hide')
      })
    },
    async getOrgMembers (data = {}) {
      if (!this.$user) return

      const response = await this.doctorRequest.getOrgMembersV2({
        orgId: this.orgId,
        status: this.stat,
        limit: data.pageSize || this.pagination.pageSize,
        page: data.currentPage || this.page,
        keyword: this.searchValue,
        sort_by: 'desc'
      })
      this.dataOrgMember = response.data?.data
      this.pagination = {
        currentPage: response.data?.meta?.current_page || 1,
        pageSize: response.data?.meta?.per_page || this.pagination.pageSize,
        totalItems: response.data?.meta?.total || 0
      }

      this.totalItems = response.data?.meta?.total || 0
    },
    async postOrgMembers () {
      // role: 1,2,3, 1-chủ phòng, 2-admin, 3-nhân viên
      const params = {
        org_id: this.org.id,
        role: 1,
        email: 'string',
        name: 'string'
      }
      this.dataOrgMember = await this.doctorRequest.postOrgMembers(params)
    },
    async deleteOrgMembers (id) {
      this.dataOrgMember = await this.doctorRequest.deleteOrgMembers(id)
    },
    async deleteMember (member) {
      if (!this.checkRoleOwnerOrg(this.org || this.$user)) {
        alert('Bạn không có quyền xóa nhân sự trong Org này')
        return
      }
      if (confirm(`Bạn muốn xóa ${member.name}`)) {
        await this.deleteOrgMembers(member.id)
        this.getOrgMembers()
      }
    },
    backOrg () {
      this.$router.push({
        path: appUtils.getOrgId() ? '/organization/management' : '/'
      })
    },
    handleBackSubOrgList () {
      this.$router.push({
        name: 'OrganizationManagement'
      })
    }
  }
}
</script>
  
<style lang="scss" scoped>
::v-deep {
  ::placeholder {
    color: #98a2b3;
  }

  .border-bottom-2 {
    border-bottom: 2px solid #20419b !important;
  }

  .custom:hover {
    background-color: #e4e7ec;
  }

  .el-pagination-mdt {
    padding: 0;

    & .number.active {
      background-color: #20419b !important;
    }
  }
}
.edit-cell {
  gap: 10px;

  .edit-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 6px;
  }
}
</style>
  